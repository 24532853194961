import React from 'react'
import './loadingBoards.css'
export default function LoadingBoards() {
  return (
    <div className='loadingSection'>
        <div className='silhouette'>
        </div>
        <div className='silhouette'>
        </div>
        <div className='silhouette'>
        </div>
        <div className='silhouette'>
        </div>
    </div>
  )
}
